<template>
  <div>
    <el-tabs v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane :label="todayLabel" name="today" lazy>
        <date-table v-if="activeTab === 'today'" :date="today" />
      </el-tab-pane>
      <el-tab-pane :label="tomorrowLabel" name="tomorrow" lazy>
        <date-table v-if="activeTab === 'tomorrow'" :date="tomorrow" />
      </el-tab-pane>
      <el-tab-pane :label="`${fomatedDate(oneDay)}`" name="oneDay" lazy>
        <date-table v-if="activeTab === 'oneDay'" :date="oneDay" />
      </el-tab-pane>
      <el-tab-pane :label="`${fomatedDate(twoDay)}`" name="twoDay" lazy>
        <date-table v-if="activeTab === 'twoDay'" :date="twoDay" />
      </el-tab-pane>
      <el-tab-pane :label="`${fomatedDate(threeDay)}`" name="threeDay" lazy>
        <date-table v-if="activeTab === 'threeDay'" :date="threeDay" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import moment from 'moment';
import DateTable from './DateTable.vue';

export default {
  name: 'Kitchens',
  components: { DateTable },
  data: () => ({
    activeTab: 'today',
  }),
  computed: {
    todayLabel() {
      return moment().locale('ru').format('ll');
    },
    tomorrowLabel() {
      return moment().add(1, 'd').locale('ru').format('ll');
    },
    today() {
      return moment().format('YYYY-MM-DD');
    },
    tomorrow() {
      return moment().add(1, 'days').format('YYYY-MM-DD');
    },
    oneDay() {
      return moment().add(2, 'days').format('YYYY-MM-DD');
    },
    twoDay() {
      return moment().add(3, 'days').format('YYYY-MM-DD');
    },
    threeDay() {
      return moment().add(4, 'days').format('YYYY-MM-DD');
    },
  },
  methods: {
    async handleClick() {
      await this.$store.dispatch('RESET_KITCHENS');
    },
    fomatedDate(date) {
      return moment(date).locale('ru').format('ll');
    },
  },
};
</script>
