<template>
  <div class="kitchen-rule-panel mb-4 mt-2">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="is-flex">
          <el-select v-model="state" placeholder="Состояние" :disabled="!selected.length">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button
            class="ml-4"
            type="success"
            icon="el-icon-check"
            :disabled="!selected.length"
            @click="changeState"
          >
            Изменить состояние
          </el-button>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="is-flex is-justify-content-flex-end">
          <el-button type="primary" icon="el-icon-refresh" @click="getNowOrder">
            Новые заказы
          </el-button>
          <el-button
            type="success"
            icon="el-icon-finished"
            :disabled="!selected.length"
            @click="sendMessageTelegram"
          >
            Отправить на кухню
          </el-button>
          <!--          <el-button-->
          <!--            v-if="isAvailable"-->
          <!--            type="success"-->
          <!--            icon="el-icon-finished"-->
          <!--            :disabled="!selected.length"-->
          <!--            @click="sendDelivery"-->
          <!--          >-->
          <!--            Отправить на доставку-->
          <!--          </el-button>-->
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'KitchenRulePanel',
  props: {
    date: { type: String, required: true },
  },
  data() {
    return {
      state: '',
      options: [
        { value: 'complete', label: 'Готово' },
        { value: 'process', label: 'В процессе' },
        { value: 'not', label: 'Не готово' },
      ],
    };
  },
  computed: {
    ...mapGetters({ selected: 'SELECTED' }),
    ...mapState({
      isAvailable: (state) => ['admin', 'administrator'].includes(state.authState.authState.role),
    }),
  },
  methods: {
    async getNowOrder() {
      await this.$store.dispatch('FETCH_KITCHENS', { date: this.date });
    },
    async changeState() {
      await this.$store.dispatch('UPDATE_KITCHENS', {
        date: this.date,
        state: this.state,
        meals: this.selected.map((item) => item.meal_id),
      });
      await this.$store.dispatch('FETCH_KITCHENS', { date: this.date });
    },
    async sendMessageTelegram() {
      try {
        const positions = [];
        this.selected.forEach((select) => select.positions.forEach((p) => positions.push(p)));
        await this.$store.dispatch('WRITE_OFF', positions);
        await this.$store.dispatch('SEND_TELEGRAM', this.selected);
      } catch (err) {
        throw new Error(err);
      }
    },
    async sendDelivery() {
      try {
        await this.$store.dispatch(
          'STORE_DELIVERY',
          ...this.selected.map((select) => select.orders)
        );
        // window.open(url);
      } catch (err) {
        throw new Error(err);
      }
    },
  },
};
</script>
