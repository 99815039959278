<template>
  <div class="kitchen-today">
    <kitchen-rule-panel :date="date" />
    <kitchen-table />
  </div>
</template>

<script>
import KitchenRulePanel from './KitchenRulePanel.vue';
import KitchenTable from './KitchenTable.vue';

export default {
  name: 'DateTable',
  components: { KitchenTable, KitchenRulePanel },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('FETCH_KITCHENS', { date: this.date });
  },
};
</script>
