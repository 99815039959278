<template>
  <el-table
    ref="multipleTable"
    v-loading="kitchens.length < 0"
    :data="kitchens"
    style="width: 100%"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55" />
    <el-table-column width="355" label="Название">
      <template slot-scope="scope">
        {{ scope.row.category }}
      </template>
    </el-table-column>
    <el-table-column property="count" label="Кол-во" width="120" />
    <el-table-column v-if="isAvailable" property="count" label="Факт. кол-во блюд">
      <template slot-scope="scope">
        <el-input
          v-model="scope.row.actual_quantity"
          size="small"
          placeholder="0"
          @input="changeActualQuantity(scope.row, scope.row.actual_quantity)"
        />
      </template>
    </el-table-column>
    <el-table-column property="count" label="Клиенты">
      <template slot-scope="scope">
        <el-select :value="`Кол-во ${scope.row.clients.length}`">
          <el-option
            v-for="item in scope.row.clients"
            :key="item.key"
            :label="item.name"
            :value="item.name"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.quantity }}</span>
          </el-option>
        </el-select>
      </template>
    </el-table-column>
    <el-table-column property="address" label="Статус">
      <template slot-scope="scope">
        <el-tag
          :type="
            scope.row.state === 'complete'
              ? 'success'
              : scope.row.state === 'process'
              ? 'warning'
              : 'danger'
          "
          disable-transitions
        >
          {{
            scope.row.state === 'complete'
              ? 'Готово'
              : scope.row.state === 'process'
              ? 'В процессе'
              : 'Не готово'
          }}
        </el-tag>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'KitchenTable',
  data() {
    return {
      client: null,
      meals: [],
    };
  },
  computed: {
    ...mapGetters({ kitchens: 'KITCHENS' }),
    ...mapState({
      isAvailable: (state) =>
        ['admin', 'administrator', 'super-admin', 'master-admin'].includes(
          state.authState.authState.role
        ),
    }),
  },
  methods: {
    async changeActualQuantity(row, actualQuantity) {
      if (isNaN(+actualQuantity)) {
        return;
      }
      await this.$store.dispatch('UPDATE_KITCHENS', {
        date: row.date,
        meals: [row.meal_id],
        actual_quantity: actualQuantity,
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.$store.commit('SET_SELECTED', val);
    },
  },
};
</script>
